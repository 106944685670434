import { createRouter, createWebHistory } from 'vue-router'
import LoginView from '../views/LoginView.vue'
import HomeView from '../views/HomeView.vue'
import AdminView from '../views/AdminView.vue'

import UserService from '../services/UserService'

const routes = [
  {
    path: '/home',
    name: 'home',
    component: HomeView
  },
  {
    path: '/admin',
    name: 'admin',
    component: AdminView,
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    props: true,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/:catchAll(.*)',
    name: 'home',
    component: HomeView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.beforeEach((to, _from, next) => {
  console.log('Router before each', to.matched)
  if (to.matched.some(record => record.meta.requiresAuth)) {
    UserService.isAuthorized().then((_authed) => {
      console.log('isauthed', _authed)
      if (_authed === true) {
        if (to.matched.some(record => record.meta.adminOnly)) {
          if (UserService.isAdmin()) {
            next()
          } else {
            next(false)
          }
        } else {
          next()
        }
      } else {
        next({
          path: '/login'
        })
      }
    })
  } else {
    next()
  }
})

export default router
